import React from "react";
import PropTypes from "prop-types"
import BookNow from "./BookNow";


const ScheduleHeader = ({metadata}) => {
    return (
        <div className="schedule-page-header">
            <h4 className="schedule-page-title">{metadata.title}</h4>
            <h5 className="schedule-page-subtitle">{metadata.subtitle}</h5>
            <p className="schedule-page-header-information">{metadata.description}</p>
            {metadata.book && <BookNow />}
        </div>
    )
}

ScheduleHeader.propTypes = {
    metadata: PropTypes.object
}

ScheduleHeader.defaultProps = {
    metadata: {
        title: "Title",
        subtitle: "Subtitle",
        description: "Description goes here."
    }
}

export default ScheduleHeader