import React from "react"

const FortCollinsMeta =  {
    header: {
        title: "Fort Collins Airport Shuttle",
        subtitle: "Fort Collins | Loveland | Timnath | Wellington | Windsor",
        description: "Please note check-in closes 15 minutes before the time of departure and Landlines depart promptly at your scheduled time.\nPlease arrive early and have your ID ready to ensure an on-time departure.\n\nAs of November 7, 2024 Landline will no longer offer service to/from the 3825 E Mulberry location. \nPassengers are encouraged to use the FNL location for FREE parking. ",
        book: true
    }
    
}

const FortCollinsService = <>
    <p>Let Landline’s premium airport shuttle service between Fort Collins/ Loveland and Denver International Airport (DEN/DIA) be the best part of your travel day.</p>
    <p>Avoid the stress and expense of driving, parking or ridesharing and instead opt for Landline’s comfortable, Wi-Fi enabled vehicles.</p>
    <p>Enjoy modern conveniences like air conditioning, in seat power, reclining leather seats, and get caught up on work or stream your favorite shows while you ride.</p>
    <p>Our friendly staff will help you with loading and unloading your luggage on both ends of your trip ensuring a smooth and efficient trip.</p>
    <p>Landline is ideal for families, college students, leisure and business travelers alike looking for a safe, reliable and an affordable way to/ from DIA.</p>
</>

const DropOffData = {
    title: "Drop Off Location",
    subtitle: "Denver International Airport (DEN/DIA)",
    image: "https://content.landline.com/wp-content/uploads/2024/10/DENPickUpMap.jpeg",
    content: <>
        <p>The Landline pick-up/drop off is located on Level 5 of Terminal West at DEN. Follow the signs for United baggage claim. Landline picks up outside door 504 immediately curbside (not on an island).</p>
        <p>For passengers who booked Landline service through United Airlines, we pick up at Gate B83. Do not proceed to baggage claim, your luggage will be transferred on your behalf and can be collected upon arrival at the Northern Colorado Regional Airport.</p>
    </>
}

export {
    FortCollinsMeta, FortCollinsService, DropOffData
}