import { EditingContainer, NotificationConsumer } from "ibe-components"
import PropTypes from "prop-types"
import React, { useCallback, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Redirect } from "react-router-dom"

import { PROFILE } from "../../../constants/routes"
import accountActions from "../../../redux/account/actions"
import errorActions from "../../../redux/error/actions"
import { accountSelector, errorSelector, loyaltySelector } from "../../../redux/selector"
import { reduceErrors } from "../../../utils/common"
import {
  validateProfileBasic,
  validateProfileContact,
  validateProfileTravel,
} from "../../../utils/validation"
import StoreCreditsInfo from "../../atoms/StoreCreditsInfo"
import TabNavbarLayout from "../../layouts/TabNavbarLayout"
import LoyaltyProgram from "../../organisms/LoyaltyProgram"

const ProfilePage = ({ history }) => {
  const dispatch = useDispatch()
  const onInputChanged = useCallback(
    field => value => {
      dispatch(accountActions.setFormFieldValue({ field, value }))
    },
    [dispatch],
  )
  const editUserProfile = useCallback(() => dispatch(accountActions.editUserProfile()), [dispatch])
  const fetchUserProfile = useCallback(() => dispatch(accountActions.fetchUserProfile()), [
    dispatch,
  ])
  const clearError = useCallback(() => dispatch(errorActions.clearError()), [dispatch])

  const error = useSelector(state => errorSelector(state))
  const account = useSelector(state => accountSelector(state))
  const penguin_perks = account.profile.penguin_perks ? account.profile.penguin_perks : {tier: "TBD", trips: 0}
  const { wallet } = useSelector(state => loyaltySelector(state))
  const walletBalance = (wallet || {}).points
  const loyaltyNumber = (wallet || {}).loyalty_number

  const [state, setState] = useState({
    formErrors: {},
    modalErrors: {},
    profileChanged: false,
    passwordChanged: false,
  })

  const { data = {}, titles, genders, seats, airports, store_credits: storeCredits } =
    account.profile || {}
  const { formErrors } = state

  useEffect(() => {
    if (account.isAuthenticated) {
      fetchUserProfile()
    }
  }, [history.location])

  if (!account.isAuthenticated) {
    history.push(PROFILE)
    return <Redirect to="/login" />
  }

  const onChangePassword = () => {
    const passwordData = {
      current_password: data.current_password,
      new_password: data.new_password,
      repeated_password: data.repeated_password,
    }

    const payload = {
      passwordData,
      callback: () => setState(prevState => ({ ...prevState, passwordChanged: true })),
    }

    dispatch(accountActions.changePassword(payload))

    return true
  }

  const onSubmit = (e, title) => {
    e.preventDefault()
    const params = account.profile.data
    let errors = {}
    if (title === "Basic info") {
      errors = validateProfileBasic(params)
    } else if (title === "Contact Info") {
      errors = validateProfileContact(params)
    } else if (title === "Travel Preferences") {
      errors = validateProfileTravel(params, airports)
    }
    if (errors && Object.keys(errors).length > 0) {
      setState(prevState => ({
        ...prevState,
        formErrors: errors,
        modalErrors: reduceErrors(errors),
      }))
      return false
    }
    setState(prevState => ({
      ...prevState,
      formErrors: {},
      modalErrors: {},
      profileChanged: true,
    }))
    editUserProfile()
    return true
  }

  const basicInfoData = [
    {
      type: "DROP_DOWN",
      labelText: "TITLE",
      mandatory: true,
      error: formErrors.title,
      value: data.title,
      content: titles,
      onChange: value => {
        onInputChanged("title")(value)
      },
    },
    {
      type: "INPUT",
      labelText: "FIRST NAME",
      value: data.first_name,
      onChange: e => onInputChanged("first_name")(e.target.value),
      error: formErrors.first_name,
      mandatory: true,
    },
    {
      type: "INPUT",
      labelText: "LAST NAME",
      value: data.last_name,
      onChange: e => onInputChanged("last_name")(e.target.value),
      error: formErrors.last_name,
      mandatory: true,
    },
    {
      type: "DATE_PICKER",
      labelText: "DATE OF BIRTH",
      mandatory: true,
      error: formErrors.date_of_birth,
      value: data.date_of_birth,
      onChange: value => {
        onInputChanged("date_of_birth")(value)
      },
    },
    {
      type: "DROP_DOWN",
      labelText: "GENDER",
      mandatory: true,
      error: formErrors.gender,
      value: (data.gender || [])[1],
      content: genders,
      onChange: value => {
        onInputChanged("gender")(genders.find(gender => gender[0] === value))
      },
    },
  ]

  const contactInfoData = [
    {
      type: "INPUT",
      labelText: "PHONE",
      maskType: "phone",
      value: data.phone_number,
      onChange: e => onInputChanged("phone_number")(e.target.value),
      error: formErrors.phone_number,
      mandatory: true,
    },
    {
      type: "INPUT",
      labelText: "EMAIL",
      value: data.email,
      onChange: e => onInputChanged("email")(e.target.value),
      error: formErrors.email,
      mandatory: true,
    },
    {
      type: "INPUT",
      labelText: "EMERGENCY CONTACT",
      error: formErrors.emergency_phone,
      value: data.emergency_phone,
      onChange: e => onInputChanged("emergency_phone")(e.target.value),
    },
  ]

  const travelInfoData = [
    {
      type: "INPUT",
      labelText: "KNOWN TRAVELER ID#",
      error: formErrors.known_traveler,
      value: data.known_traveler,
      onChange: e => onInputChanged("known_traveler")(e.target.value),
    },
    {
      type: "DROP_DOWN",
      labelText: "SEAT PREFERENCE",
      content: seats,
      error: formErrors.seat_preference,
      value: data.seat_preference,
      onChange: value => onInputChanged("seat_preference")(value),
    },
    // {
    //   type: "INPUT",
    //   labelText: "PREFERRED AIRPORT",
    //   error: formErrors.preferred_airport,
    //   value: data.preferred_airport,
    //   onChange: e => onInputChanged("preferred_airport")(e.target.value),
    //   autocomplete: true,
    //   suggestions: airports,
    // },
  ]

  const passwordData = [
    {
      type: "password",
      labelText: "CURRENT PASSWORD",
      value: data.current_password,
      onChange: e => onInputChanged("current_password")(e.target.value),
    },
    {
      type: "password",
      labelText: "NEW PASSWORD",
      value: data.new_password,
      onChange: e => onInputChanged("new_password")(e.target.value),
    },
    {
      type: "password",
      labelText: "CONFIRM PASSWORD",
      value: data.repeated_password,
      onChange: e => onInputChanged("repeated_password")(e.target.value),
    },
  ]

  return (
    <NotificationConsumer>
      {({ actions }) => {
        return (
          <TabNavbarLayout
            render={() => {
              if (error && !error.isBusy) {
                setState(prevState => ({
                  ...prevState,
                  modalErrors: typeof error === "string" ? error : reduceErrors(error),
                }))
                clearError()
              }

              if (state.modalErrors) {
                actions.show({ type: "is-danger", message: state.modalErrors })
                setState(prevState => ({
                  ...prevState,
                  modalErrors: undefined,
                }))
              }

              if (state.profileChanged && account.profile.updated) {
                actions.show({
                  type: "is-success",
                  message: "Profile update successful",
                  miliseconds: 3000,
                })
                setState(prevState => ({ ...prevState, profileChanged: false }))
              }

              if (state.passwordChanged) {
                actions.show({
                  type: "is-success",
                  message: "Password has been successfuly changed",
                  miliseconds: 3000,
                })
                setState(prevState => ({ ...prevState, passwordChanged: false }))
              }
              return (
                <div
                  className="SectionWrapper"
                  style={{ padding: "30px 20px 0 20px", backgroundColor: "#FFF" }}
                >
                  <LoyaltyProgram balance={walletBalance} loyaltyNumber={loyaltyNumber} tier={penguin_perks.tier} trips={penguin_perks.eligible_trips} />
                  <StoreCreditsInfo balance={storeCredits} />
                  <EditingContainer title="Basic info" items={basicInfoData} onSave={onSubmit} />
                  <EditingContainer
                    title="Contact Info"
                    items={contactInfoData}
                    onSave={onSubmit}
                  />
                  <EditingContainer
                    title="Password"
                    items={passwordData}
                    onSave={onChangePassword}
                  />
                  <EditingContainer
                    title="Travel Preferences"
                    items={travelInfoData}
                    onSave={onSubmit}
                  />
                </div>
              )
            }}
          />
        )
      }}
    </NotificationConsumer>
  )
}

export default ProfilePage

ProfilePage.propTypes = {
  history: PropTypes.instanceOf(Object),
}
ProfilePage.defaultProps = {
  history: {},
}
