import "./TopMenuProfile.scss"

import classNames from "classnames"
import { Button, Icon } from "ibe-components"
import React, { useRef, useState } from "react"
import { useSelector } from "react-redux"
import { useHistory } from "react-router-dom"

import { accountSelector, loyaltySelector } from "../../../redux/selector"
import { useOnClickOutside } from "../../../utils/hook"
import NavLink from "../../atoms/NavLink"
import TopMenuProfileLoyalty from "../TopMenuProfileLoyalty"

const TopMenuProfile = () => {
  const { profile, isAuthenticated } = useSelector(state => accountSelector(state))
  const [openMenu, changeOpenMenu] = useState(false)

  const topMenuProfileClass = classNames("top-menu-profile-wrapper")
  const toggleMenu = () => {
    changeOpenMenu(!openMenu)
  }
  const { wallet } = useSelector(state => loyaltySelector(state))
  const walletBalance = (wallet || {}).points
  const loyaltyNumber = (wallet || {}).loyalty_number

  const ref = useRef()
  useOnClickOutside(ref, () => changeOpenMenu(false))

  const history = useHistory()

  const TopMenuIcon = () => {
    const goToLogin = () => {
      history.push("/login")
    }

    if (!(isAuthenticated && profile)) {
      return (
        <Icon
          noAbsolute
          onClick={goToLogin}
          className="top-menu-icons"
          name="profile"
          fill="#FAB500"
          width="20"
          height="20"
          viewBox="0 0 20 20"
        />
      )
    }
    return openMenu ? (
      <Icon
        noAbsolute
        onClick={toggleMenu}
        className="top-menu-icons"
        name="collapse"
        fill="#FAB500"
        width="20"
        height="12"
        viewBox="0 0 20 12"
      />
    ) : (
      <Icon
        noAbsolute
        onClick={toggleMenu}
        className="top-menu-icons"
        name="profile"
        fill="#FAB500"
        width="20"
        height="20"
        viewBox="0 0 20 20"
      />
    )
  }

  const TopMenuProfileContent = () => {
    if (!openMenu) return null

    return (
      <div>
        <div className="top-menu-profile-content">
          <span className="top-menu-profile-name-wrapper">
            Logged in as&nbsp;
            <span className="top-menu-profile-name">
              {`${profile.first_name || ""} ${profile.last_name || ""}`}
            </span>
          </span>

          <div className="top-menu-profile-navlinks-wrapper">
            <NavLink className="top-menu-profile-navlinks" to="/">
              Book Trip
            </NavLink>

            <NavLink className="top-menu-profile-navlinks" to="/trips">
              My Trips
            </NavLink>

            <NavLink className="top-menu-profile-navlinks" to="/profile">
              My Profile
            </NavLink>
          </div>
          <NavLink to="/logout">
            <Button
              className="top-menu-profile-logout"
              margin="20px 0 0 0"
              type="destructive"
              icon={<Icon className="top-menu-icons" name="exit" align="right" />}
              fullWidth
            >
              Logout
            </Button>
          </NavLink>
        </div>
      </div>
    )
  }

  return (
    <div className={topMenuProfileClass} ref={ref}>
      <div className="top-menu-profile-icon-wrapper">
        <TopMenuIcon />
      </div>
      {isAuthenticated && profile && <TopMenuProfileContent />}
    </div>
  )
}

TopMenuProfile.propTypes = {}

TopMenuProfile.defaultProps = {}

export default TopMenuProfile
