import { Button, PageTitle, Price } from "ibe-components"
import PropTypes from "prop-types"
import React from "react"
import { useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import styled from "styled-components"
import "./TotalPrice.scss"

import { BUY_MILES, PAYMENT } from "../../../constants/routes"
import { accountSelector } from "../../../redux/selector"
import Alert from "../../atoms/Alert"
import StoreCreditsForm from "../../organisms/StoreCreditsForm"
import VoucherForm from "../../organisms/VoucherForm"

const TotalPrice = ({
  voucher,
  voucherCode,
  onPromoCodeChanged,
  onAddPromoCodeClicked,
  onRemovePromoCodeClicked,
  price,
  preview,
  isLoyaltyActive,
  pricePoints,
  loyaltyBalance,
  paymentNeeded,
  setRedirectURL,
  previousTicket,
  editTrip,
  cancelTrip,
  hideVoucher,
  refundData,
}) => {
  const history = useHistory()

  const goToBuyPoints = () => {
    setRedirectURL(PAYMENT)
    history.push(BUY_MILES)
  }

  const account = useSelector(state => accountSelector(state))

  const previousCartItem = {
    total_price: previousTicket.ticket_price,
    total_price_lp: previousTicket.ticket_price_loyalty,
  } || { total_price: 0, total_price_lp: 0 }

  return (
    <TotalPriceContainer>
      {isLoyaltyActive && (
        <FeesContainer>
          <Name>Fees (Miles)</Name>
          <PriceContainer>
            <Price ceil loyaltyStyle amount={pricePoints} currency="PTS" useLoyalty={isLoyaltyActive} />
          </PriceContainer>
        </FeesContainer>
      )}
      <TaxesContainer className="tax-container">
        <Name className="tax-container__label">Taxes</Name>
        <PriceContainer>
          <Price amount={0} currency="USD" useLoyalty={isLoyaltyActive} />
        </PriceContainer>
      </TaxesContainer>
      {paymentNeeded && (
        <FeesContainer className="fee-container">
          <Name className="fee-container__label">Subtotal</Name>
          <PriceContainer>
            <Price amount={price} currency="USD" useLoyalty={isLoyaltyActive} />
          </PriceContainer>
        </FeesContainer>
      )}
      {!hideVoucher &&
        (preview ? (
          <>
            <VoucherForm
              voucher={voucher}
              voucherCode={voucherCode}
              onPromoCodeChanged={onPromoCodeChanged}
              onAddPromoCodeClicked={onAddPromoCodeClicked}
              onRemovePromoCodeClicked={onRemovePromoCodeClicked}
            />

            {account.isAuthenticated && (
              <>
                <FeesContainer className="fee-container">
                  <Name>Store Credits</Name>
                  <PriceContainer>
                    <Price amount={account.profile.store_credits} currency="USD" />
                  </PriceContainer>
                </FeesContainer>
                <StoreCreditsForm />
              </>
            )}
          </>
        ) : (
          !isLoyaltyActive && (
            <>
              <VoucherCodeContainer>
                <Name>Promo Code</Name>
                <PromoCodeContainer>
                  {voucher && voucher.data && voucher.data.vouchers_id
                    ? voucher.data.vouchers_id
                    : "0.00"}
                </PromoCodeContainer>
              </VoucherCodeContainer>
            </>
          )
        ))}
      {paymentNeeded ? (
        <div className="total-price-container">
          <Name className="total-price-container__label">Total</Name>
          <PriceContainer>
            <Price amount={price} currency="USD" />
          </PriceContainer>
        </div>
      ) : null}

      {editTrip && previousTicket.refund_details && (
        <TotalContainer>
          <>
            <NameContainer>
              <TotalName>Refund for canceled itinerary</TotalName>
              {!isLoyaltyActive && (
                <Description>(Refund will be processed in 5-10 business days)</Description>
              )}
            </NameContainer>
            <PriceContainer color="green" bold>
              {!isLoyaltyActive && (
                <Price amount={previousTicket.ticket_price} currency="USD" />
              )}
              {isLoyaltyActive && (
                <Price amount={previousTicket.ticket_price_loyalty} currency="PTS" />
              )}
            </PriceContainer>
          </>
        </TotalContainer>
      )}
      {isLoyaltyActive && preview && (
        <>
          <TotalPaymentContainer>
            <TotalPayment>Total Payment</TotalPayment>
            <PriceContainer color="#ff9800" bold>
              <Price ceil loyaltyStyle amount={pricePoints} currency="PTS" useLoyalty={isLoyaltyActive} />
            </PriceContainer>
          </TotalPaymentContainer>

          <PointsBalance>
            <BalancePoints>Post-purchase Mile Balance</BalancePoints>
            <PriceContainer>
              {loyaltyBalance - pricePoints < 0 ? (
                <div>
                  <span style={{ fontWeight: "normal", fontSize: 16, color: "#FF9800" }}>
                    Not enough miles
                  </span>
                  <Button style={{ marginTop: "10px" }} type="secondary" onClick={goToBuyPoints}>
                    {" "}
                    Buy Miles{" "}
                  </Button>
                </div>
              ) : (
                <Price
                  floor
                  useLoyalty={isLoyaltyActive}
                  amount={
                    editTrip
                      ? loyaltyBalance - pricePoints + previousCartItem.total_price_lp
                      : loyaltyBalance - pricePoints
                  }
                  currency="PTS"
                />
              )}
            </PriceContainer>
          </PointsBalance>
        </>
      )}

      {cancelTrip && refundData && refundData.amount && (
        <TotalContainer>
          <>
            <TotalName>Total Refund</TotalName>
            <PriceContainer color="green" bold>
              {!isLoyaltyActive && <Price amount={refundData.amount} currency="USD" />}
              {isLoyaltyActive && <Price amount={refundData.miles} currency="PTS" />}
            </PriceContainer>
          </>
        </TotalContainer>
      )}

      {cancelTrip && !isLoyaltyActive && refundData && refundData.amount ? (
        <Alert type="green">
          Your refund will be issued to the original credit card, this may take 5-10 business days
          to post to your account.
        </Alert>
      ) : null}

      {cancelTrip && isLoyaltyActive && refundData && refundData.miles ? (
        <Alert type="green">Your Miles refund will be issued to your account</Alert>
      ) : null}
      {typeof refundData.amount === "undefined" && previousTicket.refund_details.store_credits ? (
        <TotalPriceContainer>
          <div>
            <PageTitle title="Previous Ticket" fontSize="20px" margin="40px 0 0 0" />
            <div className="payment-price-section">
              <div className="payment-prices-row">
                <div>1x Previous Landline ticket (Refundable part)</div>
                {!isLoyaltyActive ? (
                  <Price amount={previousTicket.refund_details.amount} currency="USD" useLoyalty={isLoyaltyActive} />
                ) : (
                  <Price amount={previousTicket.refund_details.miles} currency="PTS" useLoyalty={isLoyaltyActive} />
                )}
              </div>
            </div>
            <TotalContainer>
              <TotalName>Landline Credits</TotalName>
              <PriceContainer color="#ff9800" bold>
                <Price
                  amount={previousTicket.refund_details.store_credits}
                  currency="USD"
                />
              </PriceContainer>
            </TotalContainer>
          </div>
          <Alert type="orange">
            You are issued Landline Credit as your ticket doesn’t qualify for a refund. You can use
            these for other purchases with Landline. Terms of Use apply.
          </Alert>
        </TotalPriceContainer>
      ) : null}

      {refundData.store_credits ? (
        <TotalPriceContainer>
          <TotalContainer>
            <TotalName>Landline Credits</TotalName>
            <PriceContainer color="#ff9800" bold>
              <Price amount={refundData.store_credits} currency="USD" />
            </PriceContainer>
          </TotalContainer>
          <Alert type="orange">
            You are issued Landline Credit as your ticket doesn’t qualify for a refund. You can use
            these for other purchases with Landline. Terms of Use apply.
          </Alert>
        </TotalPriceContainer>
      ) : null}
    </TotalPriceContainer>
  )
}
export default TotalPrice

TotalPrice.propTypes = {
  voucher: PropTypes.instanceOf(Object),
  voucherCode: PropTypes.string,
  onPromoCodeChanged: PropTypes.func,
  onAddPromoCodeClicked: PropTypes.func,
  onRemovePromoCodeClicked: PropTypes.func,
  price: PropTypes.number.isRequired,
  preview: PropTypes.bool,
  isLoyaltyActive: PropTypes.bool,
  pricePoints: PropTypes.number,
  loyaltyBalance: PropTypes.number.isRequired,
  paymentNeeded: PropTypes.bool,
  setRedirectURL: PropTypes.func,
  previousTicket: PropTypes.shape({
    refund_details: PropTypes.instanceOf(Object),
    ticket_price: PropTypes.number,
    ticket_price_loyalty: PropTypes.number,
  }),
  refundData: PropTypes.instanceOf(Object),
  editTrip: PropTypes.bool,
  cancelTrip: PropTypes.bool,
  hideVoucher: PropTypes.bool,
}
TotalPrice.defaultProps = {
  voucher: {},
  voucherCode: "",
  onPromoCodeChanged: () => {},
  onAddPromoCodeClicked: () => {},
  onRemovePromoCodeClicked: () => {},
  preview: false,
  isLoyaltyActive: false,
  pricePoints: 0,
  paymentNeeded: true,
  setRedirectURL: undefined,
  previousTicket: {
    refund_details: {},
  },
  editTrip: false,
  cancelTrip: false,
  hideVoucher: false,
  refundData: {},
}

// Price Container

const PriceContainer = props => {

  const fontweight = props.bold ? "bold" : "400"

  return (
    <div className="totalprice-price-container"
      style={{
        fontWeight: fontweight,
        color: props.color
      }}
    >
      { props.children }
    </div>
  )
}

// PointsBalance
const PointsBalance = props => {

  const classes = "totalprice-points-balance " + props.className

  return (
    <div className={classes} >
      { props.children }
    </div>
  )
}


// BalancePoints
const BalancePoints = props => {

  const classes = "totalprice-balance-points " + props.className

  return (
    <div className={classes}>
      { props.children }
    </div>
  )
}


// PromoCodeContainer
const PromoCodeContainer = props => {

  const classes = "totalprice-promocode-container " + props.className

  return (
    <div className={classes}>
      { props.children }
    </div>
  )
}


// 
const TotalPriceContainer = props => {

  const classes = "totalprice-total-price-container " + props.className

  return (
    <div className={classes}>
      { props.children }
    </div>
  )
}


// TotalContainer
const TotalContainer = props => {

  const classes = "totalprice-total-container " + props.className

  return (
    <div className={classes}>
      { props.children }
    </div>
  )
}


// FeesContainer
const FeesContainer = props => {

  const classes = "totalprice-fees-container " + props.className

  return (
    <div className={classes}>
      { props.children }
    </div>
  )
}

// TaxesContainer
const TaxesContainer = props => {

  const classes = "totalprice-taxes-container " + props.className

  return (
    <div className={classes}>
      { props.children }
    </div>
  )
}

// Name
const Name = props => {

  const classes = "totalprice-name " + props.className

  return (
    <div className={classes}>
      { props.children }
    </div>
  )
}

// VoucherCodeContainer
const VoucherCodeContainer = props => {

  const classes = "totalprice-voucher-code-container " + props.className

  return (
    <div className={classes}>
      { props.children }
    </div>
  )
}


// TotalName
const TotalName = props => {

  const classes = "totalprice-total-name " + props.className

  return (
    <div className={classes}>
      { props.children }
    </div>
  )
}


// NameContainer
const NameContainer = props => {

  const classes = "totalprice-name-container " + props.className

  return (
    <div className={classes}>
      { props.children }
    </div>
  )
}


// Description
const Description = props => {

  const classes = "totalprice-description " + props.className

  return (
    <div className={classes}>
      { props.children }
    </div>
  )
}


// TotalPaymentContainer
const TotalPaymentContainer = props => {

  const classes = "totalprice-total-payment-container " + props.className

  return (
    <div className={classes}
    >
      { props.children }
    </div>
  )
}


// TotalPayment
const TotalPayment = props => {

  const classes = "totalprice-total-payment " + props.className

  return (
    <div className={classes}
    >
      { props.children }
    </div>
  )
}