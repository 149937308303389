import React from "react"

const FargoMeta = {
    header: {
        title: "Fargo Airport Shuttle",
        subtitle: "Fargo | Moorhead | West Fargo",
        description: "Landline departs promptly at the scheduled time. Please check in at the Landline/Sun Country counter inside the terminal. \nYour driver or Landline agent will announce when it is time to board the motorcoach to Minneapolis-St. Paul International Airport. \nPlease arrive early and have your ID ready to ensure an on time departure.",
        book: true
    }
}

const FargoService = <>
    <p>Let Landline's premium airport shuttle service from Fargo(FAR) and Minneapolis St.Paul International Airport(MSP) be the best part of your travel day. </p>
    <p>Avoid the stress and expense of driving, parking or ridesharing and instead opt for Landline's comfortable, Wi - Fi enabled vehicles.</p>
    <p>Enjoy modern conveniences like air conditioning, in seat power, reclining leather seats, onboard restroom, and get caught up on work or stream your favorite shows while you ride.</p>
    <p>Our friendly staff will help you with loading and unloading your luggage on both ends of your trip ensuring a smooth and efficient trip.</p>
    <p>Landline is ideal for families, college students, leisure and business travelers alike looking for a safe, reliable and an affordable way to / from MSP or simply book a ride to the Twin Cities. </p>
</>

const FargoParking = {
    title: "Fargo Airport Parking",
    image: "https://content.landline.com/wp-content/uploads/2024/05/FAR_Parking_Map.png",
    content: <>
        <p>Fargo Airport has options for both short and long term parking. Please visit their website <a href="https://fargoairportparking.com/" target="_blank">here</a>, to learn more about rates, pre-book your parking stay, payment options and more. If you have additional questions, please contact the <a href="https://www.fargoairport.com/" target="_blank">Hector International Airport</a> directly.</p>
    </>
}


export {
    FargoMeta, FargoService, FargoParking
}
