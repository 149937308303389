import React from "react";
import PropTypes from "prop-types"


const BookNow = () => {

    const goToBookPage = () => {
        window.location.href = "https://landline.com/"
      }
    return (
        <button className="schedule-book-now" onClick={goToBookPage}>Book Now</button>
    )
}

export default BookNow