import "./ReservationSummaryPage.scss"

import classNames from "classnames"
import {
  Button,
  Icon,
  ItinerarySingleRow,
  Loading,
  NotificationConsumer,
  PageTitle,
  Price,
  ReservationHeader,
} from "ibe-components"
import PropTypes from "prop-types"
import React, { useCallback, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import featureFlags from "../../../constants/feature-flags"
import ticketActions from "../../../redux/ticket/actions"
import itineraryActions from "../../../redux/itinerary/actions"
import redirectActions from "../../../redux/redirect/actions"
import reservationActions from "../../../redux/reservations/actions"
import {
  errorSelector,
  itinerarySelector,
  loyaltySelector,
  passengerSelector,
  reservationsSelector,
  ticketSelector,
  tripSelector,
} from "../../../redux/selector"
import tripActions from "../../../redux/trip/actions"
import { trackAddToCartActionTradeDesk } from "../../../utils/trackingService"
import Breadcrumbs from "../../atoms/Breadcrumbs"
import ErrorBoundary from "../../atoms/ErrorBoundary"
import PageLayout from "../../layouts/PageLayout"
import { getCityName, getSectionType } from "../../../utils/tripUtil-util"
import {
  decreaseDateBy,
  increaseDateBy,
  isDateAfter,
  toDateFormatString,
} from "../../../utils/common"
import Search from "../HomePage/Search"
import moment from "moment"
import { DEPARTURE_TICKET } from "../../../constants/ticket"

const ReservationSummaryPage = ({ history }) => {
  const dispatch = useDispatch()
  const reservationSummaryPageClass = classNames("reservation-summary-page-wrapper")
  const bookingTicket = useSelector(state => ticketSelector(state))
  const loyalty = useSelector(state => loyaltySelector(state))
  const trip = useSelector(state => tripSelector(state))
  const reservations = useSelector(state => reservationsSelector(state))
  const itinerary = useSelector(state => itinerarySelector(state))
  const passenger = useSelector(state => passengerSelector(state))
  const [collapsed, setCollapsed] = useState({})

  const error = useSelector(state => errorSelector(state))

  const isLoyaltyActive = loyalty.isActive

  const setRedirectURL = useCallback(url => dispatch(redirectActions.setRedirectURL(url)), [
    dispatch,
  ])
  const updateItinerary = useCallback(async () => {
    await trackAddToCartActionTradeDesk()
    dispatch(itineraryActions.updateItinerary(history))
  }, [dispatch])

  const clearFlightDetails = useCallback(() => dispatch(tripActions.clearFlightDetails()), [
    dispatch,
  ])
  const getFlightDetails = useCallback(
    flightRequestData => dispatch(tripActions.getFlightDetails(flightRequestData)),
    [dispatch],
  )
  useEffect(() => {
    clearFlightDetails()
    dispatch(reservationActions.setShouldCheckHeartbeat(false))
  }, [])

  const params = reservations.params || {}
  const [state, setState] = useState({
    shouldShowTripSummaryModal: false,
    currentDepartureItineraryIndex: -1,
    currentReturnItineraryIndex: -1,
    error: undefined,
    redirectToNext: false,
    mustLogin: false,
    filters: { ...reservations.filters },
    showModalFilters: false,
  })

  useEffect(() => {
    setState(prevState => ({
      ...prevState,
      filters: {
        ...prevState.filters,
        ...reservations.filters,
      },
    }))
  }, [reservations.filters])

  const setTicketByType = useCallback(params => dispatch(ticketActions.setTicketByType(params)), [
    dispatch,
  ])

  const updateReservation = useCallback(() => dispatch(reservationActions.updateReservation()), [
    dispatch,
  ])

  const setDepartureTicket = ({
    segments,
    segmentIndex,
    ticket,
    ticketIndex,
    summaryPrice,
    layoverTimes,
    totalTripTime,
    returnFlights,
    bookingToken,
  }) => {
    setTicketByType({
      type: DEPARTURE_TICKET,
      segments,
      segmentIndex,
      ticket,
      ticketIndex,
      layoverTimes,
      summaryPrice,
      totalTripTime,
      returnFlights,
      bookingToken,
    })
  }

  const setReservationProperty = useCallback(
    field => value => dispatch(reservationActions.setReservationProperty({ field, value })),
    [dispatch],
  )

  const setDepartureDate = date => {
    setReservationProperty("departure_date")(date)
    setDepartureTicket({
      segments: undefined,
      segmentIndex: -1,
      ticket: undefined,
      ticketIndex: -1,
      summaryPrice: undefined,
      layoverTimes: undefined,
      totalTripTime: undefined,
      bookingToken: undefined,
      returnFlights: undefined,
    })
  }

  const onDecreaseDepartureDateClick = () => {
    if (isDateAfter(params.departure_date, new Date())) {
      const date = decreaseDateBy(params.departure_date, 1)
      setDepartureDate(date)
      updateReservation()
    }
  }
  const onIncreaseDepartureDateClick = () => {
    const date = increaseDateBy(params.departure_date, 1)
    if (params.return_date && isDateAfter(date, params.return_date)) {
      setReservationProperty("return_date")(date)
    }
    setDepartureDate(date)
    updateReservation()
  }

  let departPrice
  let returnPrice
  if (isLoyaltyActive) {
    departPrice = bookingTicket?.departureTicketSummaryPrice?.price_in_miles || 0
    returnPrice = bookingTicket?.returnTicketSummaryPrice?.price_in_miles || 0
  } else {
    departPrice =
      (reservations.params.one_way
        ? bookingTicket?.departureTicketSummaryPrice?.flight_price || 0
        : 0) + (bookingTicket?.departureTicketSummaryPrice?.landline_price || 0) || 0

    returnPrice =
      (bookingTicket?.returnTicketSummaryPrice?.flight_price || 0) +
        (bookingTicket?.returnTicketSummaryPrice?.landline_price || 0) || 0
  }

  const totalPrice = departPrice + returnPrice

  return (
    <ErrorBoundary>
      <NotificationConsumer>
        {({ actions }) => {
          const isPrevDisabled = () => {
            const currentDate = moment(params.departure_date).format("MM DD YYYY")
            const minDate = params.fromMinDate
              ? moment(params.fromMinDate).format("MM DD YYYY")
              : moment().format("MM DD YYYY")

            return moment(currentDate).isSame(minDate)
          }

          const isNextDisabled = () => {
            if (params.fromMaxDate) {
              const currentDate = moment(params.departure_date).format("MM DD YYYY")
              const maxDate = moment(params.fromMaxDate).format("MM DD YYYY")

              return moment(currentDate).isSame(maxDate)
            }

            return false
          }
          return (
            <PageLayout
              render={() => {
                if (itinerary.isBusy || passenger.isBusy) {
                  return <Loading />
                }
                if (error) {
                  actions.show({ type: "is-danger", message: error })
                }
                return (
                  <div className={reservationSummaryPageClass}>
                    <Breadcrumbs
                      history={history}
                      className="reservation-summary-page-breadcrumbs"
                    />

                    <div className="SectionWrapper reservation-section-wrapper">
                      <div className="reservation-wrapper">
                        <ReservationHeader
                          isEditTripActive={trip.editTrip !== ""}
                          searchPadding={getSectionType()}
                          from={params.dep_from}
                          to={params.dep_to}
                          date={toDateFormatString(params.departure_date, "dddd, MMM DD, YYYY")}
                          onPrevClicked={onDecreaseDepartureDateClick}
                          onNextClicked={onIncreaseDepartureDateClick}
                          search={<Search history={history} remoteCallSearchComponent />}
                          getCityName={getCityName}
                          getMobileCityName={n => n.code}
                          prevDisabled={isPrevDisabled()}
                          nextDisabled={isNextDisabled()}
                        />
                        <div
                          style={{
                            marginTop: 20,
                            marginBottom: 20,
                            backgroundColor: "#fff",
                            paddingTop: 40,
                          }}
                          className="departure-ticket"
                        >
                          <PageTitle
                            title={`Departure: ${moment(
                              bookingTicket.departureTicket[0].departure_date,
                            ).format("MM/DD/yyyy")}`}
                            margin="0 20px 0 20px"
                          />
                          <ItinerarySingleRow
                            flightSearchActive={featureFlags().showFlightSearchFeatureFlag}
                            selectedItineraryIndex={0}
                            summaryPreview
                            showLegConnectionDate
                            passengersAdults={reservations.params.passengers_adults}
                            passengersChildrens={reservations.params.passengers_children}
                            passengersInfants={reservations.params.passengers_infants}
                            collapsibleLeft
                            isRefundableTicketSelected={bookingTicket.departureTicketIndex === 1}
                            useLoyalty={isLoyaltyActive}
                            itineraryIndex={0}
                            onCollapse={newColap => {
                              setCollapsed(prevState => ({ ...prevState, ...newColap }))
                            }}
                            bookingToken={bookingTicket.bookingToken}
                            totalTripTime={bookingTicket.departureTicketTotalTripTime}
                            isCollapsableOpen={collapsed[0]}
                            segments={bookingTicket.departureTicket}
                            layoverTimes={bookingTicket.departureTicketLayoverTimes}
                            departureCode={reservations.items.depart_flights[0].departure_code}
                            arrivalCode={reservations.items.depart_flights[0].arrival_code}
                            priceCeil={isLoyaltyActive}
                            currencySymbol={isLoyaltyActive ? "PTS" : "USD"}
                            tickets={bookingTicket.departureTicket[0].tickets}
                            summaryPrice={bookingTicket.departureTicketSummaryPrice}
                            classbands={reservations.items.classbands}
                            getFlightDetails={getFlightDetails}
                            luggage={trip.flightDetails[0]}
                            loading={trip.flightDetails.isBusy}
                          />
                        </div>
                        {bookingTicket.returnTicket && bookingTicket.returnFlights.length > 0 && (
                          <div
                            style={{ marginBottom: 20, backgroundColor: "#fff" }}
                            className="return-ticket"
                          >
                            <PageTitle
                              title={`Return: ${moment(
                                bookingTicket.returnTicket[0].departure_date,
                              ).format("MM/DD/yyyy")}`}
                              margin="0 20px 0 20px"
                            />
                            <ItinerarySingleRow
                              flightSearchActive={featureFlags().showFlightSearchFeatureFlag}
                              selectedItineraryIndex={1}
                              isRefundableTicketSelected={bookingTicket.returnTicketIndex === 1}
                              showLegConnectionDate
                              passengersAdults={reservations.params.passengers_adults}
                              passengersChildrens={reservations.params.passengers_children}
                              passengersInfants={reservations.params.passengers_infants}
                              itineraryIndex={1}
                              onCollapse={newColap => {
                                setCollapsed(prevState => ({ ...prevState, ...newColap }))
                              }}
                              isCollapsableOpen={collapsed[1]}
                              summaryPreview
                              totalTripTime={bookingTicket.returnTicketTotalTripTime}
                              collapsibleLeft
                              useLoyalty={isLoyaltyActive}
                              segments={bookingTicket.returnTicket}
                              bookingToken={bookingTicket.bookingToken}
                              layoverTimes={bookingTicket.returnTicketLayoverTimes}
                              departureCode={bookingTicket.returnFlights[0].departure_code}
                              summaryPrice={bookingTicket.returnTicketSummaryPrice}
                              arrivalCode={bookingTicket.returnFlights[0].arrival_code}
                              priceCeil={isLoyaltyActive}
                              currencySymbol={isLoyaltyActive ? "PTS" : "USD"}
                              tickets={bookingTicket.returnTicket[0].tickets}
                              classbands={reservations.items.classbands}
                              getFlightDetails={getFlightDetails}
                              luggage={trip.flightDetails[1]}
                              loading={trip.flightDetails.isBusy}
                            />
                          </div>
                        )}
                      </div>
                      <div className="summary-page-total-price">
                        <div className="summary-page-total-price-left">
                          <h3 className="summary-price-total-text">Total</h3>

                          <span className="summary-price-total-passenger-count">
                            {reservations.params.passengers_adults +
                              reservations.params.passengers_children +
                              reservations.params.passengers_infants}
                            x {reservations.params.one_way ? "One way" : "Round"} trip
                          </span>
                        </div>
                        <div className="summary-page-total-price-right">
                          <Price
                            amount={totalPrice}
                            ceil={isLoyaltyActive}
                            useLoyalty={isLoyaltyActive}
                            currency={isLoyaltyActive ? "PTS" : "USD"}
                            loyaltyStyle={isLoyaltyActive}
                            showDecimals={false}
                          />
                          <Button
                            fullWidth
                            onClick={updateItinerary}
                            type="primary"
                            buttonType="submit"
                            className={"summary-page__continue"}
                          >
                            Continue
                          </Button>
                        </div>
                      </div>
                      <div className="summary-page-total-price-mobile">
                        <div className="summary-page-total-price-mobile-container">
                          <span className="summary-price-total-passenger-count">
                            {reservations.params.passengers_adults +
                              reservations.params.passengers_children +
                              reservations.params.passengers_infants}
                            x {reservations.params.one_way ? "One way" : "Round"} trip
                          </span>
                          <Price
                            amount={totalPrice}
                            useLoyalty={isLoyaltyActive}
                            ceil={isLoyaltyActive}
                            currency={isLoyaltyActive ? "PTS" : "USD"}
                            loyaltyStyle={isLoyaltyActive}
                          />
                        </div>
                        <Button
                          fullWidth
                          onClick={updateItinerary}
                          type="primary"
                          buttonType="submit"
                          className={"summary-page__continue"}
                        >
                          Continue
                        </Button>
                      </div>
                    </div>
                  </div>
                )
              }}
            />
          )
        }}
      </NotificationConsumer>
    </ErrorBoundary>
  )
}

ReservationSummaryPage.propTypes = {
  history: PropTypes.instanceOf(Object),
}
ReservationSummaryPage.defaultProps = {
  history: {},
}

export default ReservationSummaryPage
